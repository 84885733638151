import * as React from "react";
import { Container, Row, Col, Carousel, ProgressBar } from "react-bootstrap";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";
import NewsSlider from "../components/news-slider/news-slider";
import TeamSlider from "../components/team-slider/team-slider";
import TestimonialSlider from "../components/testimonial-slider/testimonial-slider";

import "./../style/style.scss";

const progressHomeCare = 85;
const progressSeniorCare = 90;
const progressPersonalCare = 80;
const progressHealthCare = 97;
// markup
const IndexPage = () => {
  return (
    <Layout>
      <>
        <section className="sec-slider">
          <Carousel fade>
            <Carousel.Item
              style={{
                backgroundImage: `url('../image-slider/img-6.jpg')`,
              }}
            >
              <Carousel.Caption>
                <h1>Need a Nurse or Carer Today?</h1>
                <Link
                  to="/book-appointment"
                  className="btn btn-primary btn-lg pt-3 pb-3 ps-5 pe-5"
                >
                  Click Here <i className="bi bi-arrow-right"></i>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </section>

        <section className="sec-service">
          <Container>
            <Row>
              <Col lg="4" className="service-item">
                <div className="icon-box">
                  <i className="bi bi-gear"></i>
                </div>
                <h4>Quality</h4>
                <p>
                  We are committed to delivering the finest quality services,
                  and can be relied upon for our knowledge, accuracy,
                  consistency, efficiency, and responsibility.
                </p>
              </Col>
              <Col lg="4" className="service-item">
                <div className="icon-box">
                  <i className="bi bi-person"></i>
                </div>
                <h4>Customer Focus</h4>
                <p>
                  We never fail to remember that the customer is the reason we
                  are in business.
                </p>
              </Col>
              <Col lg="4" className="service-item">
                <div className="icon-box">
                  <i className="bi bi-graph-up-arrow"></i>
                </div>
                <h4>Growth</h4>
                <p>
                  We are committed to personal and professional growth, and to
                  constantly building and improving our business.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="sec-about">
          <Container>
            <Row>
              <Col lg="6">
                <div className="about-content">
                  <div className="title mb-3">About Us</div>
                  {/* <h3>Lorem Ipsum is simply dummy text</h3> */}
                  <p>
                    First Call Healthcare Services (FHS) is one of the UK's
                    fastest-growing healthcare professional recruitment
                    companies.
                  </p>
                  <p>
                    With over 14 years of experience, our approach to healthcare
                    recruitment is highly streamlined. We provide services to
                    private and public organisations through our unique
                    solutions across the UK.
                  </p>
                  <p>
                    International healthcare recruitment has become increasingly
                    important as the NHS and other healthcare providers in the
                    UK face staffing shortages. In recent years, healthcare
                    professionals from across the world have been recruited to
                    work in the UK, and this trend is likely to continue as the
                    demand for healthcare workers continues to rise.
                  </p>
                  <Link to="/about-us" className="btn btn-primary">
                    View More <i className="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </Col>
              <Col lg="6">
                <div className="d-flex">
                  <div className="img-wrap">
                    <img
                      className="d-block w-100"
                      src="../images/img-500x500-3.jpg"
                      alt="First slide"
                    />
                  </div>

                  <div className="img-wrap">
                    <img
                      className="d-block w-100"
                      src="../images/img-500x500-2.jpg"
                      alt="First slide"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="sec-who-we-are"> 
                    <Container fluid>
                        <Row>
                            <Col lg="6">
                                <div className="img-wrap">
                                    <img
                                        src="../images/image-2.jpg"
                                        alt="First slide"
                                    />
                                </div>
                            </Col>

                            <Col lg="6">
                                <div className="whoweare-content">
                                    <div className="title">Who we are</div>
                                    <h3>10 Years of Experience in Home Care & Private Nursing</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    <div className="progress-group">
                                        <div className="progress-main">
                                            <h5>Medical treatment</h5>
                                            <ProgressBar now={progressHomeCare} label={`${progressHomeCare}%`} />
                                        </div>
                                        <div className="progress-main">
                                            <h5>Emergency help</h5>
                                            <ProgressBar now={progressSeniorCare} label={`${progressSeniorCare}%`} />
                                        </div>
                                        <div className="progress-main">
                                            <h5>Qualified Doctors</h5>
                                            <ProgressBar now={progressPersonalCare} label={`${progressPersonalCare}%`} />
                                        </div>
                                        <div className="progress-main">
                                            <h5>Client satisfaction</h5>
                                            <ProgressBar now={progressHealthCare} label={`${progressHealthCare}%`} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}

        {/* <section className="sec-services">
                    <div className="background-overlay" style={{ backgroundImage: `url('../images/dotted-map.png')`}}></div>
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="services-content">
                                    <div className="title">Our Services</div>
                                    <h3>Lorem Ipsum is simply dummy text</h3>
                                </div>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" lg="4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Medical Checkup</h3>
                                        <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <Link to="/services" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" lg="4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Health Consultation</h3>
                                        <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <Link to="/services" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" lg="4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Health Medicine</h3>
                                        <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <Link to="/services" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" lg="4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Home Care</h3>
                                        <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <Link to="/services" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" lg="4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Senior Care</h3>
                                        <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <Link to="/services" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" lg="4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Personal Care</h3>
                                        <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <Link to="/services" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}

        <section
          className="sec-appointment"
          style={{
            backgroundImage: `url('../images/image-2.jpg')`,
          }}
        >
          <Container>
            <Row>
              <Col lg={{ span: 8, offset: 2 }}>
                <div className="appointment-content">
                  <div className="title">Book a nurse or carer</div>
                  <p>
                    You can book shifts via following methods: by E-mail, by fax
                    or telephone Our Registered Nurses and Care Assistants can
                    be booked on an AD-HOC or ongoing basis, Short notice,
                    single shift covers for your immediate needs.
                  </p>

                  <Link
                    to="/book-appointment"
                    className="btn btn-primary btn-lg mt-3"
                  >
                    Book Appointment
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="sec-our-team">
                    <Container>
                        <div className="client-content">
                            <div className="title">Our Staff</div>
                            <h3>Lorem Ipsum is simply dummy text</h3>
                        </div>

                        <TeamSlider />
                    </Container>
                </section>

                <section className="sec-news">
                    <Container>
                        <div className="news-content">
                            <div className="title">Latest News</div>
                            <h3>Lorem Ipsum is simply dummy text</h3>
                        </div>

                        <NewsSlider />
                    </Container>
                </section> */}

        {/* <section className="sec-testimonial">
                    <div className="background-overlay" style={{
                        backgroundImage: `url('../images/dotted-map.png')`
                    }}></div>
                    <Container>
                        <div className="testimonial-content">
                            <div className="title">Our happy Patients</div>
                            <h3>Lorem Ipsum is simply dummy text</h3>
                        </div>
                        <Row>
                            <Col lg={{ span:8, offset:2 }}>
                                <TestimonialSlider />
                            </Col>
                        </Row>
                    </Container>
                </section> */}
      </>
    </Layout>
  );
};

export default IndexPage;
