import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./team-slider.scss";

export default class TeamSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
            breakpoint: 1299,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
        <div className="team-wrapper">
            <Slider {...settings}>

                <div className="slide-team-member">
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-1.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-1.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Beatriz</h5>
                            <h6>( Medical Checkup )</h6>
                        </div>
                </div>
                <div className="slide-team-member">  
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-2.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Linnea</h5>
                            <h6>( Health Consultation )</h6>
                        </div>
                </div>
                <div className="slide-team-member">
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-3.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-3.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Amara</h5>
                            <h6>( Health Medicine )</h6>
                        </div>
                </div>
                <div className="slide-team-member">
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-4.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-4.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Penelope</h5>
                            <h6>( Home Care )</h6>
                        </div>
                </div>
                <div className="slide-team-member">
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-5.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-5.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Apollonia</h5>
                            <h6>( Senior Care )</h6>
                        </div>
                </div>
                <div className="slide-team-member">
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-1.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-1.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Beatriz</h5>
                            <h6>( Medical Checkup )</h6>
                        </div>
                </div>
                <div className="slide-team-member">  
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-2.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Linnea</h5>
                            <h6>( Health Consultation )</h6>
                        </div>
                </div>
                <div className="slide-team-member">
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-3.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-3.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Amara</h5>
                            <h6>( Health Medicine )</h6>
                        </div>
                </div>
                <div className="slide-team-member">
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-4.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-4.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Penelope</h5>
                            <h6>( Home Care )</h6>
                        </div>
                </div>
                <div className="slide-team-member">
                        <div className="img-thumb" style={{ backgroundImage: "url('./nurses/nurse-5.jpg')", backgroundPosition: "0px 15%" }}></div>
                        {/* <div className="img-thumb">
                            <img src="./nurses/nurse-5.jpg" alt="nurse thumb" />
                        </div> */}
                        <div className="content">
                            <h5>Apollonia</h5>
                            <h6>( Senior Care )</h6>
                        </div>
                </div>

            </Slider>
        </div>
    );
  }
}
