import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "gatsby"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./news-slider.scss";

export default class NewsSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
        <div className="news-wrapper">
            <Slider {...settings}>
                <div className="news-item">                    
                    <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                    {/* <div className="img-thumb">
                        <img src="./images/image-2.jpg" alt="news thumb" />
                    </div> */}
                    <div className="news-content">
                        <h4>News Title</h4>
                        <h6>1 March 2022 / Super Admin</h6>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                        <Link to="/news/details" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                    </div>
                </div>
                <div className="news-item">                    
                    <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                    {/* <div className="img-thumb">
                        <img src="./images/image-2.jpg" alt="news thumb" />
                    </div> */}
                    <div className="news-content">
                        <h4>News Title</h4>
                        <h6>1 March 2022 / Super Admin</h6>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                        <Link to="/news/details" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                    </div>
                </div>
                <div className="news-item">                    
                    <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                    {/* <div className="img-thumb">
                        <img src="./images/image-2.jpg" alt="news thumb" />
                    </div> */}
                    <div className="news-content">
                        <h4>News Title</h4>
                        <h6>1 March 2022 / Super Admin</h6>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                        <Link to="/news/details" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                    </div>
                </div>
                <div className="news-item">                    
                    <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                    {/* <div className="img-thumb">
                        <img src="./images/image-2.jpg" alt="news thumb" />
                    </div> */}
                    <div className="news-content">
                        <h4>News Title</h4>
                        <h6>1 March 2022 / Super Admin</h6>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                        <Link to="/news/details" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                    </div>
                </div>
                <div className="news-item">                    
                    <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                    {/* <div className="img-thumb">
                        <img src="./images/image-2.jpg" alt="news thumb" />
                    </div> */}
                    <div className="news-content">
                        <h4>News Title</h4>
                        <h6>1 March 2022 / Super Admin</h6>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                        <Link to="/news/details" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                    </div>
                </div>
                <div className="news-item">                    
                    <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                    {/* <div className="img-thumb">
                        <img src="./images/image-2.jpg" alt="news thumb" />
                    </div> */}
                    <div className="news-content">
                        <h4>News Title</h4>
                        <h6>1 March 2022 / Super Admin</h6>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                        <Link to="/news/details" className="btn btn-link">Read More <i className="bi bi-arrow-right ms-2"></i></Link>
                    </div>
                </div>
            </Slider>
        </div>
    );
  }
}
